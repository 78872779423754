import React from "react"
import { Link } from "gatsby"
import { MemberstackProtected, SignInModal, ProfileModal } from "@memberstack/react"

import Layout from "../components/layout"
import SeO from "../components/seo"


const AccountPage = () => {

    return (
        <Layout>
            <SeO title="Mon Profil" />

            <div>
                <MemberstackProtected onUnauthorized={<SignInModal />}>
                    <ProfileModal />
                    <Link href="/dashboard">Retour au dashboard</Link>
                </MemberstackProtected>
            </div>
        </Layout>
    )
}

export default AccountPage